// ---------------------------------------------------------------------------------
// CAUTION -- UNUSED COMPONENT
// ---------------------------------------------------------------------------------
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import {graphql} from 'gatsby';
import Helmet from 'react-helmet';
import BlogCard from "../components/BlogCard";
import SEO from '../components/seo';


const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: '4.5rem',
        "@media (min-width: 1441px)": {
            paddingTop: '7rem',
        },
        "@media (min-width: 2041px)": {
            paddingTop: '10rem',
        }
    },
    header: {
        fontSize: '36px',
        paddingLeft: '6px',
    },
    grid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(400px, 1fr))',
    },
    pagination: {
        marginTop: '1rem',
        marginBottom: '2rem',
        width: '75%',
    },
}))

interface Props {
    data: any
}

const HomePage: React.FC<Props> = ({data}) => {
    const POST_PER_PAGE = 6
    const classes = useStyles()
    const {
        allMarkdownRemark: {edges},
    } = data
    const [page] = React.useState(1)
    const totalPosts: any[] = edges.filter((edge: { node: { frontmatter: { date: any } } }) => !!edge.node.frontmatter.date)

    const Posts = edges
        .filter((edge: { node: { frontmatter: { date: any } } }) => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
        .map((edge: { node: { id: string | number | null | undefined; frontmatter: { title: string; slug: string; date: string }; excerpt: string } }) => (
            <BlogCard
                key={edge.node.id}
                title={edge.node.frontmatter.title}
                slug={edge.node.frontmatter.slug}
                //imageUrl={edge.node.frontmatter.imageUrl.publicURL}
                date={edge.node.frontmatter.date}
                excerpt={edge.node.excerpt}
            />
        ))

    return (
        <>
            <Helmet>
                <meta name="keywords"
                      content="property tax protest, lower property tax, reduce property tax, sales comps, equity comparables"/>
                <link rel="preconnect" href="https://fonts.gstatic.com/"/>
            </Helmet>
            <SEO title="Property Tax Blogs"/>
            <Container className={classes.root}>
                <h2 className={classes.header}>Property Tax Blogs</h2>
                <div className={classes.grid}>{Posts}</div>
            </Container>
        </>
    )
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
          }
        }
      }
    }
  }
`

export default HomePage